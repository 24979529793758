// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-billing-detail-index-js": () => import("./../../../src/Templates/BillingDetail/index.js" /* webpackChunkName: "component---src-templates-billing-detail-index-js" */),
  "component---src-templates-billing-list-index-js": () => import("./../../../src/Templates/BillingList/index.js" /* webpackChunkName: "component---src-templates-billing-list-index-js" */),
  "component---src-templates-customer-detail-index-js": () => import("./../../../src/Templates/CustomerDetail/index.js" /* webpackChunkName: "component---src-templates-customer-detail-index-js" */),
  "component---src-templates-customer-list-index-js": () => import("./../../../src/Templates/CustomerList/index.js" /* webpackChunkName: "component---src-templates-customer-list-index-js" */),
  "component---src-templates-dispatch-detail-index-js": () => import("./../../../src/Templates/DispatchDetail/index.js" /* webpackChunkName: "component---src-templates-dispatch-detail-index-js" */),
  "component---src-templates-pdf-preview-js": () => import("./../../../src/Templates/PdfPreview.js" /* webpackChunkName: "component---src-templates-pdf-preview-js" */),
  "component---src-templates-project-detail-index-js": () => import("./../../../src/Templates/ProjectDetail/index.js" /* webpackChunkName: "component---src-templates-project-detail-index-js" */),
  "component---src-templates-quotation-detail-index-js": () => import("./../../../src/Templates/QuotationDetail/index.js" /* webpackChunkName: "component---src-templates-quotation-detail-index-js" */),
  "component---src-templates-quotation-list-index-js": () => import("./../../../src/Templates/QuotationList/index.js" /* webpackChunkName: "component---src-templates-quotation-list-index-js" */),
  "component---src-templates-staff-login-index-js": () => import("./../../../src/Templates/StaffLogin/index.js" /* webpackChunkName: "component---src-templates-staff-login-index-js" */),
  "component---src-templates-supplier-detail-index-js": () => import("./../../../src/Templates/SupplierDetail/index.js" /* webpackChunkName: "component---src-templates-supplier-detail-index-js" */),
  "component---src-templates-supplier-list-index-js": () => import("./../../../src/Templates/SupplierList/index.js" /* webpackChunkName: "component---src-templates-supplier-list-index-js" */),
  "component---src-templates-test-page-js": () => import("./../../../src/Templates/TestPage.js" /* webpackChunkName: "component---src-templates-test-page-js" */),
  "component---src-templates-user-list-index-js": () => import("./../../../src/Templates/UserList/index.js" /* webpackChunkName: "component---src-templates-user-list-index-js" */)
}

