import React from 'react';
import {DatePicker, Space} from 'antd';
import moment from 'moment';

export default function DateTimePicker(props) {
  let {value, onChange = () => {}} = props;

  return (
    <Space>
      <DatePicker
        defaultValue={moment(value)}
        onChange={(value) => {
          onChange(value ? value.toDate() : null);
        }}
        format="YYYY-MM-DD HH:mm"
        showTime
        style={{
          width: 250,
        }}
      />
    </Space>
  );
}
