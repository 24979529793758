import React, {useEffect, useState} from 'react';
import {Table, Input} from 'antd';
import {Check} from '@styled-icons/material';

const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

const defaultFields = ['name'];
const defaultQuery = {};

export default function SearchList(props) {
  const {
    labels = [],
    fields = defaultFields, // search fields
    query = defaultQuery,
    getRecords,
    onClick = (value) => console.log('select', value),
    keyOfValue = 'id',
    value = null,
    title,
  } = props;
  const [records, setRecords] = useState([]);
  const [filters, _setFilters] = useState({
    search: '',
    ...PAGINATION_INIT,
  });
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const columns = fields.map((field, idx) => ({
    title: labels[field],
    render: (record) =>
      idx === 0 ? (
        <div
          onClick={() => onClick(record)}
          style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
          <div style={{flex: '0 0 40px'}}>
            {value === record[keyOfValue] && <Check color="#555" size="16" />}
          </div>
          <div>{record[field]}</div>
        </div>
      ) : (
        <div>{record[field]}</div>
      ),
    // width: '100%',
  }));

  function setFilters(obj) {
    _setFilters((prev) => ({
      ...prev,
      ...PAGINATION_INIT,
      ...obj,
    }));
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const params = {
          query: {
            $or: fields.map((field) => ({[field]: {$regex: filters.search}})),
            ...query,
          },
          paging: {
            offset: (filters.current - 1) * filters.pageSize,
            limit: filters.pageSize,
          },
          sorting: ['-created'],
        };
        let resp = await getRecords(params);
        setRecords(resp.results);
        setTotal(resp.total);
      } catch (err) {
        console.warn(err);
      }
      setLoading(false);
      return () => setRecords([]);
    })();
  }, [getRecords, filters, fields, query]);

  return (
    <div style={{padding: 20}}>
      <div style={{marginBottom: 20, display: 'flex', alignItems: 'center'}}>
        <span style={{marginRight: 10}}>{title}</span>
        <Input.Search
          placeholder={`搜尋 ${fields.map((f) => labels[f]).join(', ')}`}
          style={{width: 250}}
          allowClear
          enterButton
          loading={loading}
          onSearch={(value) => setFilters({search: value})}
        />
      </div>
      {records && (
        <Table
          loading={loading}
          columns={columns}
          dataSource={records}
          rowKey={(record) => record.id || record.name}
          pagination={{
            pageSize: filters.pageSize,
            current: filters.current,
            total,
          }}
          onChange={(pagination) =>
            setFilters({
              pageSize: pagination.pageSize,
              current: pagination.current,
            })
          }
        />
      )}
    </div>
  );
}
