import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Ant from 'antd';
import {navigate} from 'gatsby';
import 'antd/dist/antd.less';
import '../index.css';
import ActivityIndicator from './ActivityIndicator';
import Toast from './Toast';
import {Modal} from '../Widgets';
import {Context} from '../AppContext';
import {UserOutlined} from '@ant-design/icons';
import ChangePasswordForm from '../Forms/ChangePasswordForm';
const appConfig = require('../data.json');

function MenuItem(props) {
  const {selected, onClick} = props;
  return (
    <MenuItemWrapper selected={selected} onClick={onClick}>
      {props.children}
    </MenuItemWrapper>
  );
}

const MenuItemWrapper = styled.button`
  margin: 10px;
  width: 180px;
  border: none;
  background-color: ${(props) =>
    props.selected ? appConfig.colors.main + '1' : 'transparent'};
  color: ${(props) => (props.selected ? appConfig.colors.main : '#ccc')};
  border-radius: 4px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  cursor: pointer;
`;

const EXPLICT_PATH = ['/', '/admin/login'];

const Layout = ({children, location}) => {
  let showAdminSider = true;
  const app = useContext(Context);
  const {user} = app.state;

  if (EXPLICT_PATH.indexOf(location.pathname) > -1) {
    showAdminSider = false;
  }

  function onLogout() {
    app.actions.logout();
    navigate('/');
  }

  function onChangePassword() {
    navigate('/');
  }

  const getMenuProps = (path) => {
    return {
      selected: path === location.pathname,
      onClick: () => navigate(path),
    };
  };

  return (
    <>
      {showAdminSider ? (
        <Ant.Layout>
          <Ant.Layout.Sider
            theme="light"
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'fixed',
              boxShadow:
                '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
              left: 0,
            }}>
            <AppHeader style={{marginBottom: 40}} />

            <p style={{color: '#aaa', padding: '0 25px'}}>
              <UserOutlined style={{marginRight: 5}} />
              {user ? user.data.fullname : '-'}
            </p>

            <MenuItem {...getMenuProps('/customers')}>客戶管理</MenuItem>

            <MenuItem {...getMenuProps('/suppliers')}>廠商管理</MenuItem>

            <MenuItem {...getMenuProps('/users')}>員工管理</MenuItem>

            <MenuItem {...getMenuProps('/quotations')}>報價單</MenuItem>

            <MenuItem {...getMenuProps('/billings')}>帳務管理</MenuItem>

            <MenuItem
              onClick={() => {
                app.actions.setModal(<ChangePasswordForm />);
              }}
              style={{}}>
              修改密碼
            </MenuItem>

            <MenuItem onClick={onLogout} style={{}}>
              登出
            </MenuItem>
          </Ant.Layout.Sider>

          <Ant.Layout style={{marginLeft: 200}}>{children}</Ant.Layout>
        </Ant.Layout>
      ) : (
        <main>{children}</main>
      )}

      <Toast />
      <Modal />
      <ActivityIndicator />
    </>
  );
};

function AppHeader(props) {
  const {style = {}} = props;

  return (
    <AppHeaderWrapper style={style}>
      <img src="/images/logo.png" alt="not found" />

      <div className="content">
        <p style={{color: '#555555'}}>展示</p>
        <p style={{color: '#ccc'}}>內部報價系統</p>
      </div>
    </AppHeaderWrapper>
  );
}

const AppHeaderWrapper = styled.header`
  background-color: white;
  display: flex;
  align-items: center;
  padding: 16px;

  & > img {
    width: 60px;
    height: auto;
    margin: 0px;
  }

  & > .content {
    margin-left: 8px;

    & p {
      padding: 0;
      margin: 0;
    }
  }
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
