import {Select as AntSelect} from 'antd';
import styled from 'styled-components';

const Select = styled(AntSelect)`
  flex-basis: 150px;
`;

const SelectOption = styled(AntSelect.Option)`
  width: 150px;
`;

export default Select;
export {SelectOption};
