import {message} from 'antd';

class ErrCustomBase extends Error {}
class ErrLogin extends ErrCustomBase {}
class ErrCustomer extends ErrCustomBase {}
class ErrSupplier extends ErrCustomBase {}
class ErrPricing extends ErrCustomBase {}
class ErrProject extends ErrCustomBase {}
class ErrProjectItem extends ErrCustomBase {}
class ErrDispatch extends ErrCustomBase {}
class ErrQuotation extends ErrCustomBase {}
class ErrUser extends ErrCustomBase {}
class ErrBilling extends ErrCustomBase {}
class ErrProcess extends Error {}

function errorHandler(ex, defaultMessage = '發生錯誤') {
  console.warn(ex);
  if (ex instanceof ErrCustomBase) {
    message.warning(ex.message);
  } else {
    message.error(defaultMessage);
  }
}

export {
  ErrLogin,
  ErrCustomer,
  ErrSupplier,
  ErrPricing,
  ErrProject,
  ErrProjectItem,
  ErrDispatch,
  ErrQuotation,
  ErrUser,
  ErrBilling,
  ErrProcess,
  errorHandler,
};
