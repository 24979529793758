const {navigate} = require('gatsby');
const {message} = require('antd');

export default function previous(path = '') {
  if (typeof window !== 'undefined' && window.history.length > 0) {
    return window.history.back();
  } else if (path && path.indexOf('undefined') < 0) {
    return navigate(path);
  } else {
    return message.info('找不到上一頁');
  }
}
