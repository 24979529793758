import React, {useContext, useEffect} from 'react';
import {Context} from '../AppContext';
import {Modal as AntModal, Spin} from 'antd';

export default function Modal() {
  const app = useContext(Context);
  const {modalContent, modalLoading, modalWidth} = app.state;

  useEffect(() => {
    if (!modalContent) {
      app.actions.setModalWidth(520);
    }
  }, [modalContent]);

  return (
    <AntModal
      title={null}
      footer={null}
      bodyStyle={{padding: 0, minHeight: 200}}
      visible={!!modalContent}
      onCancel={() => app.actions.setModal()}
      width={modalWidth}>
      <Spin spinning={modalLoading}>{modalContent}</Spin>
    </AntModal>
  );
}
