class User {
  constructor(record) {
    const {
      id = '',
      username = '',
      password = '',
      confirmPassword = '',
      fullname = '',
      phone = '',
    } = (record = {});

    this.id = id;
    this.username = username;
    this.password = password;
    this.confirmPassword = confirmPassword;
    this.fullname = fullname;
    this.phone = phone;
  }
}

User.labels = {
  id: '員工編號',
  username: '帳號',
  password: '密碼',
  confirmPassword: '確認密碼',
  created: '建立日期',
  fullname: '姓名',
  phone: '手機',
};

module.exports = User;
