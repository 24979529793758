import round from './round';

function calcTotal(items) {
  let sales = round(items.reduce((sum, x) => sum + x.price, 0));
  let tax = round(sales * 0.05);
  let total = round(sales + tax);

  return {sales, tax, total};
}

export default {
  calcTotal,
};
