const _date = {
  format: (date, formating = 'YYYY-MM-DD HH:mm') => {
    try {
      if (isNaN(date)) {
        throw new Error();
      }
      let value = date;

      // timestamp digit < 13, not millisecond
      if (date.toString().length < 13) {
        value = date * 1000;
      }

      // timezone offset;
      const offset = new Date().getTimezoneOffset() * 60000;
      const str = new Date(value - offset).toISOString();

      // formating YYYY-MM-DD HH:mm
      if (formating === 'YYYY-MM-DD') {
        return str.slice(0, 10);
      }
      return str.slice(0, 16).replace('T', ' ');
    } catch (error) {
      return '-';
    }
  },

  toDate: (value) => {
    if (value.toString().length < 13) {
      return new Date(value * 1000);
    }
    return new Date(value);
  },

  parseToTimestamp: (date) => {
    let timestamp = date.getTime();
    return timestamp;
  },

  getIdDate: (date) => {
    //YYYYMMDD
    try {
      if (isNaN(date)) {
        throw new Error();
      }
      let value = date;

      // timestamp digit < 13, not millisecond
      if (date.toString().length < 13) {
        value = date * 1000;
      }

      const offset = new Date().getTimezoneOffset() * 60000;
      const str = new Date(value - offset).toISOString();
      return str.slice(0, 10).replaceAll('-', '');
    } catch (error) {
      return '-';
    }
  },
};

module.exports = _date;
