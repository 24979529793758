import React from 'react';
import * as AppContext from './AppContext';
import Layout from './Components/Layout';
import usePageContext from './hooks/use-page-context';

export function PageContainer(props) {
  const {apiReady, autoLogining} = usePageContext(props);
  if (!apiReady || autoLogining) {
    return null;
  }
  return <Layout {...props}>{props.children}</Layout>;
}

export function AppRoot(props) {
  return <AppContext.Provider {...props}>{props.children}</AppContext.Provider>;
}
