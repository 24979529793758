import React, {useEffect, useState, useContext} from 'react';
import styled from 'styled-components';
import {
  Input,
  TextArea,
  Select,
  SelectOption,
  Checkbox,
  DateTimePicker,
  Button,
} from '../Widgets';
import {Row as AntRow, Col} from 'antd';
import {date} from '../Utils';
import {Context} from '../AppContext';
import SearchList from '../Components/SearchList';
import {Search} from '@styled-icons/material';

export default function useForm({onUpdate, ...props}) {
  const [record, _setRecord] = useState(props.record || {});
  const app = useContext(Context);

  const setRecord = (obj) => _setRecord((prev) => ({...prev, ...obj}));

  const renderInput = (label, name, {style, extraProps, hint = null} = {}) => {
    let value = record[name];
    return (
      <Row>
        <Label label={label}>{label}</Label>
        <Input
          value={value}
          style={style}
          onChange={(e) => setRecord({[name]: e.target.value})}
          {...extraProps}
        />
        <div className="hint">{hint}</div>
      </Row>
    );
  };

  const renderText = (label, name, {style, hint = null} = {}) => {
    let value = record[name];
    return (
      <Row>
        <Label label={label}>{label}</Label>
        <p style={{...style}}>{value}</p>
        <div className="hint">{hint}</div>
      </Row>
    );
  };

  const renderTextArea = (label, name, {style} = {}) => {
    let value = record[name];
    return (
      <Row>
        <Label label={label}>{label}</Label>
        <TextArea
          value={value}
          style={style}
          onChange={(e) => setRecord({[name]: e.target.value})}
        />
      </Row>
    );
  };

  const renderCheckbox = (
    label,
    name,
    {style, extraProps, hint = null} = {},
  ) => {
    let value = record[name];
    return (
      <Row>
        <Label label={label}>{label}</Label>
        <Checkbox
          type="checkbox"
          checked={value}
          style={style}
          onChange={(e) => setRecord({[name]: e.target.checked})}
          {...extraProps}
        />
        <div className="hint">{hint}</div>
      </Row>
    );
  };

  const renderCheckboxGroup = (
    label,
    name,
    {options, style, extraProps, hint = null} = {},
  ) => {
    let value = record[name];
    return (
      <Row>
        <Label label={label}>{label}</Label>
        <Checkbox.Group
          defaultValue={value}
          style={style}
          onChange={(values) => setRecord({[name]: values})}
          {...extraProps}>
          <AntRow gutter={[10, 10]}>
            {options.map((opt, idx) => (
              <Col lg={6} md={8} key={idx}>
                <Checkbox value={opt.value}>{opt.label}</Checkbox>
              </Col>
            ))}
          </AntRow>
        </Checkbox.Group>
        <div className="hint">{hint}</div>
      </Row>
    );
  };

  const renderSelect = (label, name, {options, style, onDepend} = {}) => {
    let value = record[name];

    options.unshift({label: '未選擇', value: null});

    return (
      <Row>
        <Label label={label}>{label}</Label>
        <Select
          value={value}
          onChange={(_value) => {
            setRecord({[name]: _value});

            if (typeof onDepend === 'function') {
              setRecord(onDepend(_value));
            }
          }}
          style={style}>
          {options.map((option, idx) => (
            <SelectOption value={option.value} key={idx}>
              {option.label}
            </SelectOption>
          ))}
        </Select>
      </Row>
    );
  };

  const renderFetchSelect = (
    label,
    name,
    {getRecords, fields, style, keyOfValue = 'id'} = {},
  ) => {
    let value = record[name];

    return (
      <Row>
        <Label label={label}>{label}</Label>
        <Button
          type="default"
          style={{...style, paddingRight: 10}}
          onClick={() => {
            app.actions.setModal(
              <SearchList
                getRecords={getRecords}
                fields={fields}
                label={[label]}
                onClick={(_record) => {
                  setRecord({[name]: _record[keyOfValue]});
                  app.actions.setModal();
                }}
                value={value}
              />,
            );
          }}>
          {value || '未選擇'}{' '}
          <span style={{marginLeft: 10}}>
            <Search color="#bbb" size={14} />
          </span>
        </Button>
      </Row>
    );
  };

  const renderDateTime = (label, name, {style, hint = null} = {}) => {
    let value = record[name] || date.parseToTimestamp(new Date());

    return (
      <Row>
        <Label label={label}>{label}</Label>
        <DateTimePicker
          value={date.toDate(value)}
          onChange={(datetime) => {
            let _datetime = date.parseToTimestamp(datetime);
            setRecord({[name]: _datetime});
          }}
          style={{...style}}
        />
        <div className="hint">{hint}</div>
      </Row>
    );
  };

  const renderCustom = (label, name, {style, render} = {}) => {
    let value = record[name];

    return (
      <Row>
        <Label label={label}>{label}</Label>
        {typeof render === 'function'
          ? render({value, record, setRecord})
          : 'render is not a function'}
      </Row>
    );
  };

  useEffect(() => {
    _setRecord(props.record || {});
  }, [props.record]);

  useEffect(() => {
    if (typeof onUpdate === 'function') {
      onUpdate(record);
    }
  }, [record]);

  return {
    renderInput,
    renderText,
    renderTextArea,
    renderCheckbox,
    renderCheckboxGroup,
    renderSelect,
    renderFetchSelect,
    renderCustom,
    renderDateTime,
    record,
  };
}

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  min-height: 30px;
  & > p {
    font-size: 13px;
    margin: 0;
    color: #19233b;
  }

  & > .hint {
    margin-left: 10px;
  }
`;

const Label = styled.h4`
  min-width: ${(props) => (props.label ? '100px' : 'auto')};
  font-size: 13px;
  font-weight: 600;
  color: #19233b;
  margin-bottom: 0;
`;
