import React from 'react';
import ApiFactory from './ApiProxy';
const config = require('./data.json');

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const rmFields = (params) => {
  let {id, _id, created, updated, ...editable} = params.data;
  params.data = editable;
  return params;
};

const Context = React.createContext();

const api = new ApiFactory({});

class Provider extends React.Component {
  constructor(props) {
    super(props);
    console.log('App initialization');
    this.state = {
      config,
      loading: false,
      autoLogining: true,
      toastContent: null,
      modalContent: null,
      modalLoading: false,
      modalWidth: 520,
      user: null,
    };

    this.actions = {
      setLoading: (loading) => this.setState({loading}),
      setAutologing: (autoLogining) => this.setState({autoLogining}),
      setToast: (toastContent) => this.setState({toastContent}),
      setModal: (modalContent = null) => this.setState({modalContent}),
      setModalLoading: (loading) => this.setState({modalLoading: loading}),
      setModalWidth: (modalWidth) => this.setState({modalWidth}),

      getApiHost: async () => {
        if (api.apiUrl) {
          return;
        }

        // const resp = await fetch(
        //   `${config.endpoints.revServiceHost}?client_id=${config.client}`,
        // );

        // if (resp.status.toString()[0] !== '2') {
        //   console.error(resp);
        //   throw new Error('取得 Service Error');
        // }
        // let services = await resp.json();

        // // get json store srv
        // let service = services.find((sv) => sv.service_name === 'jstorage');
        // const apiHost = `${service.domain}/${service.latest}`;

        const apiHost = config.endpoints.apiHost;
        api.setUrl(apiHost);
      },

      login: async ({username, password}) => {
        try {
          let resp = await api.post({
            path: `${config.endpoints.sensInternalHost}/user/login`,
            withHost: true,
            data: {username, password},
            secure: false,
          });
          api.setToken(resp.token);
          window.localStorage.setItem('token', resp.token);
          window.localStorage.setItem('refresh', resp.refresh);

          // get profile
          let user = await api.get({
            path: `${config.endpoints.sensInternalHost}/user/profile`,
            withHost: true,
          });
          this.setState({user});
        } catch (error) {
          window.localStorage.removeItem('token');
          window.localStorage.removeItem('refresh');
          api.setToken('');
          this.setState({user: null});
          throw error;
        }
      },

      autologin: async () => {
        //TODO: api not working
        if (typeof window !== 'undefined') {
          try {
            let refresh = window.localStorage.getItem('refresh');
            if (refresh) {
              let {token} = await api.get({
                path: `${config.endpoints.authHost}/jwt/access?refresh_token=${refresh}`,
                withHost: true,
                secure: false,
              });

              api.setToken(token);
              window.localStorage.setItem('token', token);

              // get profile
              let user = await api.get({
                path: `${config.endpoints.sensInternalHost}/user/profile`,
                withHost: true,
              });
              this.setState({user});
            } else {
              throw new Error('沒有 refresh token');
            }
          } catch (error) {
            window.localStorage.removeItem('token');
            window.localStorage.removeItem('refresh');
            api.setToken('');
            this.setState({user: null});
            this.setState({autoLogining: false});
            throw error;
          }
        }
        this.setState({autoLogining: false});
      },

      logout: () => {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('refresh');
        api.setToken('');
        this.setState({user: null});
      },

      getUsers: (data) => {
        return api.get({
          path: `${config.endpoints.sensInternalHost}/user/all`,
          withHost: true,
          data,
        });
      },

      addUser: (data) => {
        return api.post({
          path: `${config.endpoints.sensInternalHost}/user/create`,
          withHost: true,
          data: data,
        });
      },

      editUser: (username, data) => {
        return api.put({
          path: `${config.endpoints.sensInternalHost}/user/profile/${username}`,
          withHost: true,
          data: rmFields(data),
        });
      },

      changePassword: (data) => {
        return api.put({
          path: `${config.endpoints.sensInternalHost}/user/change-password`,
          withHost: true,
          data,
        });
      },

      getCustomers: (data) => {
        return api.post({
          path: `/document/customer/find`,
          data,
        });
      },

      getCustomer: (data) => {
        return api.post({
          path: `/document/customer/find-one`,
          data,
        });
      },

      addCustomer: (data) => {
        return api.post({
          path: `/document/customer/create`,
          data: rmFields(data),
        });
      },

      editCustomer: (data) => {
        return api.post({
          path: `/document/customer/update`,
          data: rmFields(data),
        });
      },

      getSuppliers: (data) => {
        return api.post({
          path: `/document/supplier/find`,
          data,
        });
      },

      getSupplier: (data) => {
        return api.post({
          path: `/document/supplier/find-one`,
          data,
        });
      },

      addSupplier: (data) => {
        return api.post({
          path: `/document/supplier/create`,
          data: rmFields(data),
        });
      },

      editSupplier: (data) => {
        return api.post({
          path: `/document/supplier/update`,
          data: rmFields(data),
        });
      },

      getPricings: (data) => {
        return api.post({
          path: `/document/pricing/find`,
          data,
        });
      },

      getPricing: (data) => {
        return api.post({
          path: `/document/pricing/find-one`,
          data,
        });
      },

      addPricing: (data) => {
        return api.post({
          path: `/document/pricing/create`,
          data: rmFields(data),
        });
      },

      editPricing: (data) => {
        return api.post({
          path: `/document/pricing/update`,
          data: rmFields(data),
        });
      },

      getProjects: (data) => {
        return api.post({
          path: `/document/project/find`,
          data,
        });
      },

      getProject: (data) => {
        return api.post({
          path: `/document/project/find-one`,
          data,
        });
      },

      addProject: (data) => {
        return api.post({
          path: `/document/project/create`,
          data: rmFields(data),
        });
      },

      editProject: (data) => {
        return api.post({
          path: `/document/project/update`,
          data: rmFields(data),
        });
      },

      getDispatchs: (data) => {
        return api.post({
          path: `/document/dispatch/find`,
          data,
        });
      },

      getDispatch: (data) => {
        return api.post({
          path: `/document/dispatch/find-one`,
          data,
        });
      },

      // addDispatch: (data) => {
      //   return api.post({
      //     path: `/document/dispatch/create`,
      //     data: rmFields(data),
      //   });
      // },

      addDispatch: (data) => {
        return api.post({
          path: `${config.endpoints.sensInternalHost}/dispatch/`,
          withHost: true,
          data: rmFields(data),
        });
      },

      addDispatchVersion: (data) => {
        return api.post({
          path: `${config.endpoints.sensInternalHost}/dispatch/version/`,
          withHost: true,
          data: rmFields(data),
        });
      },

      editDispatch: (data) => {
        return api.post({
          path: `/document/dispatch/update`,
          data: rmFields(data),
        });
      },

      copyDispatches: (data) => {
        return api.post({
          path: `${config.endpoints.sensInternalHost}/dispatch/copy/`,
          withHost: true,
          data,
        });
      },

      editDispatches: (dispatches) => {
        let actions = dispatches.map(
          ({_id, id, created, updated, ...data}) => ({
            method: 'update_one',
            payload: {
              query: {id},
              data,
            },
          }),
        );

        return api.post({
          path: `/document/dispatch/bulk-write`,
          data: {actions},
        });
      },

      getDispatchRecords: (data) => {
        return api.post({
          path: `/document/dispatch_record/find`,
          data,
        });
      },

      getDispatchRecord: (data) => {
        return api.post({
          path: `/document/dispatch_record/find-one`,
          data,
        });
      },

      addDispatchRecord: (data) => {
        return api.post({
          path: `/document/dispatch_record/create`,
          data: rmFields(data),
        });
      },

      editDispatchRecord: (data) => {
        return api.post({
          path: `/document/dispatch_record/update`,
          data: rmFields(data),
        });
      },

      removeDispatchRecord: (data) => {
        return api.post({
          path: `/document/dispatch_record/find-one/delete`,
          data: rmFields(data),
        });
      },

      getQuotations: (data) => {
        return api.post({
          path: `/document/quotation/find`,
          data,
        });
      },

      getQuotation: (data) => {
        return api.post({
          path: `/document/quotation/find-one`,
          data,
        });
      },

      // addQuotation: (data) => {
      //   return api.post({
      //     path: `/document/quotation/create`,
      //     data: rmFields(data),
      //   });
      // },

      addQuotation: (data) => {
        return api.post({
          path: `${config.endpoints.sensInternalHost}/quotation/`,
          withHost: true,
          data: rmFields(data),
        });
      },

      addQuotationVersion: (data) => {
        return api.post({
          path: `${config.endpoints.sensInternalHost}/quotation/version/`,
          withHost: true,
          data: rmFields(data),
        });
      },

      editQuotation: (data) => {
        return api.post({
          path: `/document/quotation/update`,
          data: rmFields(data),
        });
      },

      getBillings: (data) => {
        return api.post({
          path: `/document/billing/find`,
          data,
        });
      },

      getBilling: (data) => {
        return api.post({
          path: `/document/billing/find-one`,
          data,
        });
      },

      addBilling: (data) => {
        return api.post({
          path: `/document/billing/create`,
          data: rmFields(data),
        });
      },

      editBilling: (data) => {
        return api.post({
          path: `/document/billing/update`,
          data: rmFields(data),
        });
      },

      getBillingInfo: (quotation_id) => {
        return api.get({
          path: `${config.endpoints.sensInternalHost}/quotation/billing_info/${quotation_id}`,
          withHost: true,
        });
      },

      addBillings: (billings) => {
        let actions = billings.map((b) => ({
          method: 'insert_one',
          payload: {
            query: {},
            data: b,
          },
        }));

        return api.post({
          path: `/document/billing/bulk-write`,
          data: {actions},
        });
      },

      editBillings: (billings) => {
        let actions = billings.map(({_id, id, created, updated, ...data}) => ({
          method: 'update_one',
          payload: {
            query: {id},
            data,
          },
        }));

        return api.post({
          path: `/document/billing/bulk-write`,
          data: {actions},
        });
      },
    };
  }

  render() {
    return (
      <Context.Provider
        value={{
          state: this.state,
          actions: this.actions,
        }}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export {Context, Provider};
