import React, {useContext, useEffect} from 'react';
import {Context} from '../AppContext';
import useForm from '../hooks/use-form';
import styled from 'styled-components';
import {Button} from '../Widgets';
import User from '../Models/User';
import formatValidator from '../Utils/formatValidator';
import {ErrUser, errorHandler} from '../errors';
import {message} from 'antd';
import {Fragment} from 'react';

const {isNotEmpty, isLetter, isMobileNumber} = formatValidator;

const _record = {
  old_password: '',
  new_password: '',
  confirm_password: '',
};

export default function ChangePasswordForm({}) {
  const {renderInput, renderText, record} = useForm({record: _record});

  const app = useContext(Context);

  const valid = () => {
    if (!isNotEmpty(record.old_password)) {
      throw new ErrUser('舊密碼不能為空');
    }
    if (!isNotEmpty(record.new_password)) {
      throw new ErrUser('新密碼不能為空');
    }
    if (record.new_password !== record.confirm_password) {
      throw new ErrUser('新密碼和確認密碼不同');
    }
  };

  const submit = async () => {
    const action = '修改密碼';
    app.actions.setModalLoading(true);
    try {
      valid(record);

      await app.actions.changePassword(record);

      app.actions.setModal();
      message.success(action + '成功');
    } catch (ex) {
      errorHandler(ex, action + '錯誤');
    }
    app.actions.setModalLoading(false);
  };

  return (
    <Wrapper>
      <h3>修改密碼</h3>
      <div className="content">
        {renderInput('舊密碼', 'old_password', {
          extraProps: {
            type: 'password',
          },
        })}
        {renderInput('新密碼', 'new_password', {
          extraProps: {
            type: 'password',
          },
        })}

        {renderInput('確認密碼', 'confirm_password', {
          extraProps: {
            type: 'password',
          },
        })}
      </div>
      <div className="footer">
        <Button onClick={submit}>確認</Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > h3 {
    padding: 20px 30px;
  }

  & > .content {
    flex: 1;
    padding: 30px;
  }

  & > .footer {
    border-top: solid 1px #ddd;
    padding: 20px 30px;
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
