const moment = require('moment');

function getDeadline(deal_date, pay_period) {
  // 感官結帳日 每月25號
  // = 成交時間＋票期 之後最近的 25 號

  let sum = moment(deal_date).add(pay_period, 'days');
  let result;
  if (sum.date() <= 25) {
    // this month
    result = sum.set({date: 25});
  } else {
    // next month
    result = sum.add(1, 'months').set({date: 25});
  }
  return result.toDate();
}

module.exports = {
  getDeadline,
};
