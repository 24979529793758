import React, {useContext, useEffect, useState, useCallback} from 'react';
import {Context} from '../AppContext';
import {navigate} from 'gatsby';
import {message} from 'antd';
import {ErrLogin, errorHandler} from '../errors';

export default function usePageContext({pageContext}) {
  const [apiReady, setReady] = useState(false);
  const app = useContext(Context);
  const {autoLogining} = app.state;
  // const {} = pageContext;

  const getApiHost = useCallback(async () => {
    try {
      await app.actions.getApiHost();
      console.log('set api host');
    } catch (err) {
      console.warn(err);
    }
  }, [app.actions]);

  useEffect(() => {
    (async () => {
      await getApiHost();
      setReady(true);
    })();
  }, [getApiHost]);

  useEffect(() => {
    (async () => {
      if (!app.state.user) {
        const messaging = message.loading('正在嘗試自動登入', 0);
        try {
          await app.actions.autologin();
        } catch (err) {
          console.warn('autologin error', err);
          if (err.error === 'refresh_revoke') {
            message.error('有其他登入使用此帳號，請重新登入');
          }
          navigate('/');
        }
        messaging();
      } else {
        app.actions.setAutologing(false);
      }
    })();
  }, [app.actions, app.state.user]);

  return {
    apiReady,
    autoLogining,
  };
}
