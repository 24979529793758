import React from 'react';
import styled from 'styled-components';
const config = require('../data.json');

const sizes = {
  xl: 20,
  lg: 18,
  md: 16,
  sm: 14,
  xs: 12,
};
export default function Text(props) {
  const {size = 'md', color = config.colors.text, style} = props;

  return (
    <div
      style={{
        fontSize: sizes[size],
        color,
        ...style,
      }}>
      {props.children}
    </div>
  );
}
